<script setup lang="ts">
import type { IPostResponse } from '@/modules/home/types/home.d'

const { data } = useFetch<IPostResponse>('/api/blog', {
  query: {
    location: useLocationParam(),
  },
  key: 'BlogFeaturedPosts',
  transform: snakeToCamel,
})
</script>

<template>
  <section v-if="data" class="container mx-auto flex flex-col items-center px-4">
    <h3 class="text-xl mb-1 xl:text-4xl">
      Já conhece o blog da Trevo?
    </h3>
    <p class="mb-5 text-center text-sm xl:mb-6 xl:text-left xl:text-2xl">
      Confira abaixo algumas das nossas matérias
      mais acessadas
    </p>
    <HorizontalScroll>
      <HomeBlogLatestPostItem v-for="(post, postIdx) in data.items" :key="`post${postIdx}`" :post="post" />
    </HorizontalScroll>
  </section>
</template>
