<script setup lang="ts">
import { TestABGroups } from '@/modules/core/enums'

const title = 'Saúde Trevo - Exames, consultas, cirurgias'
const description
  = 'A trevo nasceu para facilitar sua decisão na hora de ir no médico, fazer um exame ou mesmo um procedimento cirúrgico. Oferecemos serviços de saúde com qualidade, preço que cabe no seu bolso, sem você precisar pagar todo mês. Possuímos a melhor rede de médicos, laboratórios e hospitais e você só paga quando usar.'
const ogImage
  = 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp'
const url = 'https://saudetrevo.com.br'

useServerSeoMeta({
  title,
  ogTitle: title,
  twitterTitle: title,

  description,
  ogDescription: description,
  twitterDescription: description,

  ogImage,
  twitterImage: ogImage,

  ogUrl: url,
  robots: {
    index: true,
    follow: true,
  },
  twitterCard: 'summary',
})

useHead({
  link: [
    {
      rel: 'canonical',
      href: `https://saudetrevo.com.br/${useLocationParam().value}`,
    },
  ],
})

useSeoMeta({
  articleModifiedTime: '2024-05-05',
})

const showCategories = ref<boolean>(false)

onNuxtReady(() => {
  if (useErrorMessage().value) {
    const { $toast } = useNuxtApp()

    $toast.warning(useErrorMessage().value)
  }

  if (abTest() === TestABGroups.SHOW_CATEGORIES) {
    showCategories.value = true
  }
})
</script>

<template>
  <div id="home-page">
    <HomeBannerSaude10 />
    <HomeProcedureCategory v-if="showCategories" />
    <HomeMostSearched v-else />
    <HomeBenefitsCarrosel />
    <HomeGetToKnow />
    <HomeHowItWorks />
    <HomeBlogLatestPostList />
    <HomeBannerGuaranteedPurchase />
    <HomeDepoiments />
  </div>
</template>
