export default (
  value: any,
  locale = 'pt-BR',
  options = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  },
) => {
  if (!value)
    return ''

  if (typeof value === 'string')
    value = Number.parseFloat(value)

  return value.toLocaleString(locale, options)
}
