<script setup lang="ts">
const beneficts = ref([
  {
    name: 'Garantia de qualidade',
    img: 'https://web-cdn.saudetrevo.com.br/icons/garantia-de-qualidade.svg',
    text: 'Laboratórios parceiros cuidadosamente selecionados para garantir os mais altos padrões de qualidade em seus exames',
  },
  {
    name: 'Liberdade de escolha',
    img: 'https://web-cdn.saudetrevo.com.br/icons/liberdade-de-escolha.svg',
    text: 'Transparência total no custo dos seus exames, para que você possa tomar decisões assertivas sobre a sua saúde, sem surpresas',
  },
  {
    name: 'Suporte Técnico Qualificado',
    img: 'https://web-cdn.saudetrevo.com.br/icons/icone-suporte-tecnico.svg',
    text: 'Time técnico disponível a um clique de distância oferecendo auxílio e tirando suas dúvidas',
  },
  {
    name: 'Preço justo',
    img: 'https://web-cdn.saudetrevo.com.br/icons/icone-preco-justo.svg',
    text: 'Ampla rede de laboratórios parceiros, garantindo acesso a preços competitivos. Buscar os melhores preços nunca foi tão fácil!',
  },
])

const activeBenefictIndex = ref(0)

function nextBenefict() {
  if (activeBenefictIndex.value < beneficts.value.length - 1)
    activeBenefictIndex.value++
}

function previousBenefict() {
  if (activeBenefictIndex.value > 0)
    activeBenefictIndex.value--
}

const isMobile = ref(false)

function handleResize() {
  isMobile.value = window.innerWidth <= 1280
}

onNuxtReady(() => {
  isMobile.value = window.innerWidth <= 1280
  window.addEventListener('resize', handleResize)
})
</script>

<template>
  <div class="px-4 my-8 xl:my-[58px]">
    <div
      class="flex items-center justify-between w-full lg:max-w-[1000px] mx-auto"
    >
      <button
        aria-label="Botão de voltar"
        class="min-w-[45px] min-h-[45px] rounded-full bg-neutral-variant-92 hover:bg-primary-50 duration-300"
        type="button"
        @click="previousBenefict"
      >
        <span class="sr-only">Botão de voltar</span>
        <Icon
          name="ic:baseline-chevron-left"
          class="min-w-[27px] min-h-[27px] md:min-w-[36px] md:min-h-[36px] text-white"
        />
      </button>

      <Carousel
        v-model="activeBenefictIndex"
        :items-to-show="isMobile ? 1 : 2"
        :wrap-around="true"
        aria-label="Carrosel de beneficios"
        :snap-align="isMobile ? 'center' : 'start'"
        class="w-[250px] flex gap-6 xl:w-[730px]"
      >
        <Slide
          v-for="(benefict, i) in beneficts"
          :key="i"
          class="flex flex-col items-center cursor-grab xl:w-[432px]"
        >
          <Img
            :src="benefict.img"
            width="80"
            height="80"
            :alt="`icone do benefício ${benefict.name}`"
            classes="w-[80px] h-[80px] mb-[14px]"
          />
          <p class="text-sm text-primary-40 font-semibold">
            {{ benefict.name }}
          </p>
          <p class="text-xs text-center">
            {{ benefict.text }}
          </p>
        </Slide>
      </Carousel>

      <button
        aria-label="Botão de avançar"
        type="button"
        class="min-w-[45px] min-h-[45px] rounded-full bg-neutral-variant-92 hover:bg-primary-50 duration-300"
        @click="nextBenefict"
      >
        <span class="sr-only">Botão de avançar</span>
        <Icon
          name="ic:baseline-chevron-right"
          class="min-w-[27px] min-h-[27px] md:min-w-[36px] md:min-h-[36px] text-white"
        />
      </button>
    </div>
  </div>
</template>

<style scoped>
.carousel {
  justify-content: start !important;
}
</style>
