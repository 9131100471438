<template>
  <div class="w-full overflow-x-auto scrolling-touch">
    <div class="flex gap-4 pb-4 lg:gap-6">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}
</style>
