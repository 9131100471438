<script setup lang="ts">
import currency from '@/utils/currency'
import type { ICategory, ICategoryItem } from '@/modules/home/types/home'

enum HomeTab {
  PROCEDURES = 'procedures',
}

const tab = ref(HomeTab.PROCEDURES)

const tabs = ref([
  {
    name: 'Exames',
    value: HomeTab.PROCEDURES,
  },
])

const baseURL = useRuntimeConfig().public.api.awsGateway
const { data } = useFetch<ICategory>('/v1/marketplace/categories', {
  baseURL,
})

const categories = computed(() => {
  return data.value?.items.map((category: ICategoryItem) => ({
    name: category.name,
    procedures: category.procedures_count,
    value: category.lower_price,
    illustration: category.image,
    slug: category.slug,
    type: category.type,
    path: '',
  }))
})
</script>

<template>
  <div
    id="procedure-category-section"
    class="flex justify-center bg-neutral-variant-96 px-4 xl:px-4 pt-[9.5rem]"
  >
    <TabNavigation v-model="tab" :tabs="tabs" class="container">
      <template v-if="tab === HomeTab.PROCEDURES">
        <ul class="flex items-center gap-9 pb-3 overflow-x-auto">
          <li
            v-for="category of categories"
            :key="category.name"
            class="category-card relative flex flex-col items-center pt-[80px] xl:pt-[124px] cursor-pointer"
            @click="$router.push({ path: `${useLocationParam().value}/categorias/${category.slug}` })"
          >
            <img
              :src="category.illustration"
              :alt="`illustração de ${category.name}`"
              class="absolute top-0 rounded-full size-[105px] xl:size-[157px] bg-white"
              width="105"
              height="105"
            >

            <div class="flex flex-col justify-center rounded-xl items-center w-[266px] h-[150px] xl:w-[360px] xl:h-[184px] bg-white hover:bg-neutral-99 hover:shadow transition ease-in-out text-primary-40">
              <span class="category-name text-center text-xl leading-6 xl:text-3xl font-normal xl:leading-9 mb-3">
                {{ category.name }}
              </span>
              <span class="category-procedure-amount text-sm font-semibold leading-5 xl:text-xl xl:font-normal xl:leading-6">
                {{ `+ ${category.procedures} exames` }}
              </span>
              <strong class="category-price font-bold leading-6 xl:leading-8 text-base xl:text-2xl">
                {{ `A partir de ${currency(category.value)}` }}
              </strong>
            </div>
          </li>
        </ul>
      </template>
    </TabNavigation>
  </div>
</template>
