<script lang="ts" setup>
import type { IMostSearchedResponse } from '@/modules/home/types/home.d'

const { data, error } = useFetch<IMostSearchedResponse>('/api/procedure/featured', {
  query: { location: useLocationParam() },
  key: 'FeaturedExams',
  transform: snakeToCamel,
})
</script>

<template>
  <div
    v-if="data"
    class="bg-neutral-variant-96 pb-8 xl:pb-16 pt-[150px] lg:pt-[240px]"
  >
    <div
      class="container mx-auto flex flex-col lg:flex-row items-center lg:items-center h-full px-3 lg:gap-8 lg:pl-[140px] 2xl:pl-[268px] lg:pr-0"
    >
      <section class="lg:max-w-[182px]">
        <h2 class="text-xl lg:text-4xl text-primary-50">
          Exames
        </h2>
        <p class="text-sm lg:text-base text-neutral-20 mt-6">
          Tenha <span class="font-bold">mais de 4.500 tipos de exames</span> à sua
          disposição, para quando você precisar.
        </p>
      </section>
      <p v-if="error" class="text-xl lg:text-4xl text-primary-50 text-center">
        Ops, deu algo de errado ao buscar os exames
      </p>
      <HorizontalScroll>
        <HomeOurExamsCard
          v-for="(item, i) in data.items"
          :key="`exam-${i}`"
          :item="item"
        />
      </HorizontalScroll>
    </div>
  </div>
</template>
