<script setup lang="ts">
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

function limitText(text: string, limit: number) {
  return text.length > limit ? `${text.substring(0, limit)}...` : text
}
</script>

<template>
  <div
    class="flex flex-col justify-between bg-white rounded-md shadow min-w-[210px] max-w-[210px] max-h-[330px] lg:min-w-[280px] lg:max-w-[280px]"
  >
    <div class="flex flex-col gap-3 p-[18px]">
      <div class="flex gap-3 text-lg text-neutral-10 w-full min-h-[56px] lg:text-2xl">
        <div class="flex items-center justify-center min-w-[36px] min-h-[36px] w-9 h-9 rounded-full bg-information-90">
          {{ item.icon }}
        </div>
        <NuxtLink
          :to="props.item.blogPage ? props.item.blogPage : `/exames/${useLocationParam().value}/${props.item.procedureDisplaySlug}`"
          no-prefetch
        >
          <span class="lg:hidden inline">{{ limitText(item.title, 21) }}</span>
          <span class="hidden lg:inline">{{ item.title }}</span>
        </NuxtLink>
      </div>
      <div class="text-neutral-30 text-sm">
        A partir de {{ $filters.currency(item.laboratories[0].price) }}
      </div>
    </div>

    <ul class="divide-y divide-neutral-80 px-[18px]">
      <li
        v-for="(lab, i) in item.laboratories"
        :key="`lab-${i}`"
        class="flex items-center justify-between text-primary-50 font-bold py-2"
      >
        <div class="flex items-center gap-3 w-full text-xs lg:text-base">
          <Img
            :src="lab.laboratoryLogo"
            width="30"
            height="30"
            alt="Logo do laboratorio"
            classes="rounded-lg size-[30px] lg:w-10 lg:h-10"
          />
          {{ limitText(lab.laboratoryName, 14) }}
        </div>

        <span class="text-xs lg:text-base">{{
          $filters.currency(lab.price)
        }}</span>
      </li>
    </ul>

    <div class="flex items-center justify-center py-[18px] px-3">
      <NuxtLink
        :to="`/${useLocationParam().value}/exames/${props.item.procedureDisplaySlug}`"
        no-prefetch
        class="cursor-pointer text-nowrap py-2 px-4 bg-primary-50 rounded-lg text-sm text-white flex items-center justify-center font-semibold"
      >
        Comprar exame
      </NuxtLink>
    </div>
  </div>
</template>
