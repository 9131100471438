<template>
  <div
    class="flex flex-col xl:flex-row items-center xl:justify-between w-full xl:max-w-[1000px] mx-auto px-4 text-neutral-20 text-center xl:text-left"
  >
    <section class="flex flex-col items-center xl:items-start xl:max-w-[470px]">
      <h2 class="text-xl xl:text-4xl">
        Uma vida com saúde <br>
        começa com
        <span class="text-primary-50 font-bold">boas escolhas</span>
      </h2>

      <Img
        src="https://web-cdn.saudetrevo.com.br/illustrations/conheca-a-trevo.svg"
        alt="Ilustração de pessoa sentada conferindo documentos"
        classes="xl:hidden"
      />

      <p class="text-sm xl:text-xl xl:mt-6 xl:leading-7 xl:max-w-[404px] mb-4">
        Ninguém compra um eletrônico ou reserva um hotel sem saber o preço.
        <span class="font-bold xl:block">
          Por que na saúde não sabemos
          <span class="text-primary-50">quanto custa o cuidado?</span>
        </span>
      </p>
      <p
        class="text-xs text-neutral-20 mb-6 xl:text-sm xl:mb-8 xl:max-w-[376px]"
      >
        Conheça a plataforma Saúde Trevo e pesquise todos <br>
        seus exames online, com transparência e agilidade!
      </p>
      <Button class="w-28 xl:w-56 h-8" @click="navigateTo('/quem-somos')">
        <span class="flex flex-row-reverse items-center xl:flex-row">
          <span>Conheça&nbsp;</span>
          <span class="hidden xl:inline">a Saúde Trevo</span>
        </span>
      </Button>
    </section>

    <section class="hidden xl:flex">
      <Img
        src="https://web-cdn.saudetrevo.com.br/illustrations/conheca-a-trevo.svg"
        alt="Ilustração de pessoa sentada conferindo pedido medico"
        width="414"
        height="414"
      />
    </section>
  </div>
</template>
