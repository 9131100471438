<template>
  <div
    class="h-[388px] bg-[url('https://web-cdn.saudetrevo.com.br/images/amigos-fazendo-ioga-juntos-no-parque-mobile.webp')] md:bg-[url('https://web-cdn.saudetrevo.com.br/images/amigos-fazendo-ioga-juntos-no-parque-desktop.webp')] bg-cover bg-top xl:bg-top xl:bg-cover bg-no-repeat py-8 flex flex-col justify-end mt-12 xl:h-[500px]"
  >
    <div
      class="container mx-auto flex flex-col items-center justify-end px-6 text-center text-white"
    >
      <h2 class="text-2xl font-bold mb-4 xl:text-4xl">
        Compra Garantida - Sem Estresse, Só Saúde!
      </h2>

      <p class="text-sm mb-6 xl:text-xl">
        Aproveite a conveniência, faça sua compra com
        tranquilidade e saiba que estamos aqui para cuidar
        de você, com a certeza de que seu investimento
        está protegido. Na Saúde Trevo, você fica livre
        de preocupações na compra de exames!
      </p>

      <Button variant="yellow" @click="navigateTo('/compra-garantida')">
        <span> Saiba mais&nbsp; </span>
        <span class="hidden xl:inline"> sobre as garantias da Saúde Trevo</span>
      </Button>
    </div>
  </div>
</template>
