<script setup lang="ts">
const list = ref([
  {
    id: 1,
    img: 'https://web-cdn.saudetrevo.com.br/illustrations/busca-todos-os-exames-que-precisa.svg',
    name: 'Busca de exames',
    text: 'Busque todos os exame que você precisa <br /> na <strong>Saúde Trevo</strong>;',
  },
  {
    id: 2,
    img: 'https://web-cdn.saudetrevo.com.br/illustrations/busca-exames-online.svg',
    name: 'Comparador de preços',
    text: 'Compare os valores e escolha o melhor <br /> laboratório para você;',
  },
  {
    id: 3,
    img: 'https://web-cdn.saudetrevo.com.br/illustrations/agende-exames-online.svg',
    name: 'Agendamento',
    text: 'Agende seu procedimento e realize o pagamento <br /> com a <strong>Saúde Trevo</strong>;',
  },
  {
    id: 4,
    img: 'https://web-cdn.saudetrevo.com.br/illustrations/busca-exames.svg',
    name: 'Atendimento com parceiros',
    text: 'Realize o atendimento no laboratório <br /> parceiro escolhido.',
  },
])

const activeItemIndex = ref(0)
</script>

<template>
  <div class="mt-[34px] mb-8 min-h-[432px]">
    <div
      class="flex flex-col items-center justify-between w-full xl:max-w-[1000px] mx-auto px-4"
    >
      <h2 class="text-[22px] xl:text-4xl">
        Como a
        <span class="text-primary-50 font-bold">Saúde Trevo</span> funciona?
      </h2>

      <div
        class="flex flex-col items-center gap-3 xl:flex-row xl:justify-between mt-6"
      >
        <section class="flex-1">
          <Carousel
            v-model="activeItemIndex"
            :items-to-show="1"
            :items-to-scroll="1"
            :wrap-around="true"
            :autoplay="5000"
            snap-align="center"
            class="w-[300px] xl:w-[428px]"
            aria-label="Como a Saúde Trevo funciona?"
          >
            <Slide
              v-for="item in list"
              :key="item.id"
              class="flex flex-col gap-2 items-center cursor-grab"
            >
              <Img
                :src="item.img"
                width="428"
                height="428"
                :alt="`ilustração de ${item.name}`"
                classes="w-[243px] h-[243px] xl:w-[428px] xl:h-[428px]"
              />
            </Slide>
          </Carousel>
        </section>

        <section
          class="flex-1 flex flex-col items-center gap-4 w-[300px] xl:w-full xl:flex-row"
        >
          <ul class="flex items-center xl:items-start gap-3 xl:flex-col xl:gap-9">
            <li
              v-for="item in list"
              :key="item.id"
              class="flex items-center gap-4 cursor-pointer"
              @click="activeItemIndex = item.id - 1"
            >
              <span
                class="min-w-[32px] min-h-[32px] xl:min-w-[40px] xl:min-h-[40px] flex items-center justify-center font-bold text-white rounded-full" :class="[
                  item.id === list[activeItemIndex].id
                    ? 'bg-primary-40'
                    : 'bg-primary-95',
                ]"
              >{{ item.id }}</span>
              <span class="w-full hidden xl:inline" v-html="item.text" />
            </li>
          </ul>
          <div
            class="text-center xl:hidden"
            v-html="list[activeItemIndex].text"
          />
        </section>
      </div>
    </div>
  </div>
</template>
