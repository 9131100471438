<script setup lang="ts">
const depositions = ref([
  {
    text: 'Minha experiência com a Saúde Trevo foi excelente, fui muito bem atendido, tive enorme facilidade para conseguir marcar os exames que eu precisava e com muita rapidez. Experiência nota 10! Recomendo a todos.“',
    name: 'Matheus',
    age: '28 anos',
    icon: 'https://web-cdn.saudetrevo.com.br/illustrations/avatar-depoimento-homem.svg',
  },
  {
    text: 'Experiência muito boa. Consegui fazer tudo rápido e deu tudo certo.”',
    name: 'Abraão',
    age: '28 anos',
    icon: 'https://web-cdn.saudetrevo.com.br/illustrations/avatar-depoimento-homem.svg',
  },
  {
    text: 'Perfeito. Estou amando e vou usar mais vezes e indicar vocês.”',
    name: 'Patrícia',
    age: '34 anos',
    icon: 'https://web-cdn.saudetrevo.com.br/illustrations/avatar-depoimento-mulher.svg',
  },
  {
    text: 'Gostei do valor e por ser perto de casa. Experiencia boa."',
    name: 'Yasmin',
    age: '26 anos',
    icon: 'https://web-cdn.saudetrevo.com.br/illustrations/avatar-depoimento-mulher.svg',
  },
])

const activeCommentMsg = ref(0)
const carousel = ref<any>(null)

function next() {
  if (activeCommentMsg.value >= depositions.value.length - 1)
    activeCommentMsg.value = 0
  else
    activeCommentMsg.value++

  carousel.value.slideTo(activeCommentMsg.value)
}

function previous() {
  if (activeCommentMsg.value <= 0)
    activeCommentMsg.value = depositions.value.length - 1
  else
    activeCommentMsg.value--

  carousel.value.slideTo(activeCommentMsg.value)
}
</script>

<template>
  <section
    id="depoiments"
    class="flex flex-col lg:flex-row items-center justify-between lg:max-w-[1000px] lg:w-[1000px] mx-auto"
  >
    <div
      class="relative z-10 w-[375px] lg:w-[600px] h-[375px] lg:h-[550px] text-center py-[124px] lg:py-[200px] mx-auto bg-[url('https://web-cdn.saudetrevo.com.br/illustrations/depoimentos-de-clientes-titulo-mobile.svg')] lg:bg-[url('https://web-cdn.saudetrevo.com.br/illustrations/depoimentos-de-clientes-titulo.svg')] bg-cover bg-no-repeat bg-center"
    >
      <h2 class="text-[32px] lg:text-5xl text-primary-50">
        O que a nossa<br>
        comunidade <br>
        tem a dizer
      </h2>
    </div>
    <div class="z-10 w-[375px] lg:w-[400px] h-[375px] text-center">
      <Carousel ref="carousel" v-model="activeCommentMsg" :wrap-around="true">
        <Slide
          v-for="(deposition, depositionIndex) in depositions"
          :key="`deposition-${depositionIndex}`"
          class="p-6 rounded-lg bg-white bg-opacity-[0.07] items-start"
        >
          <div class="flex flex-col text-primary-20 text-left w-full">
            <Icon
              name="material-symbols:format-quote-rounded"
              class="w-9 h-9 text-primary-50 rotate-180 mb-2"
            />
            <p
              class="text-sm italic font-semibold mb-6 lg:text-[20px]"
              v-html="deposition.text"
            />
            <div class="mt-2 flex items-center justify-between">
              <div class="flex items-center justify-between gap-3">
                <Img
                  :src="deposition.icon"
                  alt="Ilustração de uma pessoa"
                  width="65"
                  height="65"
                  classes="max-w-[65px] max-h-[65px] rounded-full"
                />
                <div class="flex flex-col text-primary-50 items-start">
                  <span class="font-bold">{{ deposition.name }}</span>
                  <span class="font-bold">{{ deposition.age }}</span>
                </div>
              </div>
              <div class="flex items-center gap-2 lg:mr-[40px]">
                <div
                  class="w-8 h-8 lg:w-11 lg:h-11 rounded-full text-primary-50 bg-primary-99 hover:bg-primary-50 hover:text-primary-99 duration-300 flex items-center justify-center"
                  @click="previous"
                >
                  <span class="sr-only">Botão de voltar</span>
                  <Icon name="ic:baseline-arrow-left" class="w-6 h-6" />
                </div>
                <div
                  class="w-8 h-8 lg:w-11 lg:h-11 rounded-full text-primary-50 bg-primary-99 hover:bg-primary-50 hover:text-primary-99 duration-300 flex items-center justify-center"

                  @click="next"
                >
                  <span class="sr-only">Botão de avançar</span>
                  <Icon name="ic:baseline-arrow-right" class="w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </section>
</template>
