<script setup lang="ts">
import { EventName } from '@/modules/trevo/types/enum'

const hasCouponInUrl = ref(false)
const couponAdded = ref(false)

const { addSlug, removeSlug, procedures } = useProcedures()

const initialSelected = computed(() => {
  if (!procedures.value.length || procedures.value.length === 1)
    return null as never

  return procedures.value.map(({ procedure: { name, slug, id } }: any) => ({ name, slug, id }))
})

function handleRemoveProcedure(procedure: any) {
  track(EventName.ExamRemove, {
    procedureDisplayId: procedure.id,
    displayName: procedure.name,
  })

  removeSlug(procedure.slug)
}

onNuxtReady(() => {
  if (useProcedureSlug().value.length === 1)
    useProcedureSlug().value = []

  const coupon = localStorage.getItem('selectedCode')

  if (coupon && coupon === 'SAUDE10')
    couponAdded.value = true
})
</script>

<template>
  <div
    class="flex relative bg-gradient-to-b from-[#A4C7D9] from-[10%] to-[90%] to-[#CCE5EC] px-4 justify-center xl:justify-between xl:px-0 pb-[152px] xl:pb-[160px]"
  >
    <div
      class="relative z-10 mt-5 xl:container xl:mx-auto xl:mt-0 xl:pt-8"
    >
      <h1
        class="font-bold text-primary-30 text-4xl mb-4 leading-10 xl:w-[585px] xl:text-5xl xl:mb-3 xl:leading-[65px]"
      >
        <span class="text-white">Mais economia</span> para seus exames!
      </h1>

      <h2
        class="text-primary-30 leading-none text-base font-semibold mb-3 xl:w-[477px] xl:font-normal xl:text-xl xl:leading-6"
      >
        Busque todos seus exames e encontre os melhores laboratórios perto de você.
      </h2>

      <div class="mt-4">
        <SearchProcedure
          id="banner-saude-10"
          class="relative mb-0 z-10"
          :initial-selected="initialSelected"
          without-ordenation
          @search-procedures="navigateTo(`/${useLocationParam().value}/exames?exames=${useProcedureSlug().value?.join(',')}`)"
          @remove-procedure="handleRemoveProcedure"
          @select-procedure="addSlug"
        />
        <SearchInputOCR />
      </div>
    </div>

    <div
      class="hidden xl:block absolute z-0 right-0 bottom-0 min-h-[490px] w-[799px] bg-cover bg-[url(https://web-cdn.saudetrevo.com.br/images/banner-saude10.webp)]"
    />

    <ProfilePromoAdded
      no-redirect-to-home
      :show="hasCouponInUrl"
      @close="hasCouponInUrl = false"
    />
  </div>
</template>
